<template>
  <div class="box w-full mb-4 b-t-c">
    <div class="p-2">
      <div class="flex justify-between items-center">
        <span class="text-sm font-bold capitalize p-2">
          {{ nameCreditLine }}
        </span>

        <div class="flex px-2 items-center rounded-md">
          <Button
            :icon="[isVisible ? 'pi pi-minus' : 'pi pi-plus']"
            class="p-button-rounded p-button-xs"
            :class="[isVisible ? 'p-button-warning' : 'p-button-success']"
            @click.prevent="banksDetails(creditLineId, isVisible)"
          />
        </div>
      </div>

      <div class="flex flex-col items-center dark:bg-gray-900">
        <div class="flex justify-between w-full px-2 py-1">
          <span class="text-gray-600 font-bold text-xs"># de Bancos: {{ cantidadBancos }}</span>
          <span class="text-gray-600 font-bold text-xs"># de Créditos: {{ cantidadCreditos }}</span>
        </div>
        <div class="grid bg-white dark:bg-dark-1 dark:text-gray-500 w-full sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xxl:grid-cols-5">
          <div class="flex flex-col items-center bg-light-primary rounded border-primary border border-dashed p-2 m-2">
            <div class="text-sm text-gray-500 font-medium">Total Cupo</div>
            <div class="flex items-center pt-1">
              <div class="text-base font-bold text-gray-700 ">{{ totalCupo === 0 ? '$ 0' : $h.formatCurrency(parseInt(totalCupo)) }}</div>
            </div>
          </div>
          <div class="flex flex-col items-center bg-light-warning rounded border-warning border border-dashed p-2 m-2">
            <div class="text-sm text-gray-500 font-medium">Total Disponible</div>
            <div class="flex items-center pt-1">
              <div class="text-base font-bold text-gray-700 " v-if="[3, 6, 9].includes(creditLineId)">{{ totalDisponible === 0 ? '$ 0' : $h.formatCurrency(parseInt(totalDisponible)) }}</div>
              <div class="text-base font-bold text-gray-700 " v-else >$ 0</div>
            </div>
          </div>
          <div class="flex flex-col items-center bg-light-primary rounded border-primary border border-dashed p-2 m-2">
            <div class="text-sm text-gray-500 font-medium">Total Prestamo</div>
            <div class="flex items-center pt-1">
              <div class="text-base font-bold text-gray-700 ">{{ totalDeuda === 0 ? '$ 0' : $h.formatCurrency(parseInt(totalDeuda)) }}</div>
            </div>
          </div>
          <div class="flex flex-col items-center bg-light-success rounded border-success border border-dashed p-2 m-2">
            <div class="text-sm text-gray-500 font-medium">Total Pagado</div>
            <div class="flex items-center pt-1">
              <div class="text-base font-bold text-gray-700 ">{{ totalCancelado === 0 ? '$ 0' : $h.formatCurrency(parseInt(totalCancelado)) }}</div>
            </div>
          </div>
          <div class="flex flex-col items-center bg-light-danger rounded border-danger border border-dashed p-2 m-2">
            <div class="text-sm text-gray-500 font-medium">Total Deuda</div>
            <div class="flex items-center pt-1">
              <div class="text-base font-bold text-gray-700 ">{{ totalSaldo === 0 ? '$ 0' : $h.formatCurrency(parseInt(totalSaldo)) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="isVisible"
      class="flex flex-col p-4 intro-y">
      <table-credit-lines-banks :creditLineId="creditLineId" :creditLineName="nameCreditLine" />
    </div>

  </div>
</template>

<script>

import { defineAsyncComponent } from 'vue'
export default {
  name: 'itemCreditLine',
  props: {
    creditLineId: {
      type: Number,
      default: null
    },
    nameCreditLine: {
      type: String,
      default: ''
    },
    totalCancelado: {
      type: Number,
      default: 0
    },
    totalCupo: {
      type: Number,
      default: 0
    },
    totalDeuda: {
      type: Number,
      default: 0
    },
    totalSaldo: {
      type: Number,
      default: 0
    },
    totalDisponible: {
      type: Number,
      default: 0
    },
    cantidadBancos: {
      type: String,
      default: ''
    },
    cantidadCreditos: {
      type: String,
      default: ''
    },
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  components: {
    tableCreditLinesBanks: defineAsyncComponent(() => import('../bank'))
  },
  setup (props, context) {
    const banksDetails = (creditLineId, isVisible) => {
      context.emit('banksDetails', creditLineId, isVisible)
    }

    return {
      banksDetails
    }
  }
}
</script>

<style scoped>

.border-dashed {
  border-style: dashed !important;
  border-color: #e4e6ef;
}
.bg-light-primary {
  background-color: #F1FAFF !important;
}
.bg-light-success {
  background-color: #e8fff3!important
}
.bg-light-info {
  background-color: #f8f5ff!important
}
.bg-light-warning {
  background-color: #fff8dd!important
}
.bg-light-danger {
  background-color: #fff5f8!important
}
.border-primary {
  border-color: #009ef7 !important;
}
.border-success {
  border-color: #50cd89!important
}

.border-info {
  border-color: #7239ea!important
}

.border-warning {
  border-color: #ffc700!important
}

.border-danger {
  border-color: #f1416c!important
}
.rounded {
  border-radius: .475rem !important;
}
.border-primary {
  border-color: #009ef7 !important;
}
.b-t-c {
  border-left-color: #00bcd4;
  border-left-width: 6px;
}
</style>
